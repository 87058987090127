// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useRef, useEffect, useState } from 'react'
import Menu from './menu/Menu'
import Search from '../../../shared/search/Search'
import TableView from '../shared/TableView'
import updateData from '../utils/update'
import { useTableUtils } from '../../../shared/contexts/TableUtilsContext'
import { useColumnSelection } from '../../../shared/contexts/ColumnSelectionContext'
import { useData } from '../../../shared/contexts/DataContext'
import { useScrapeDate } from '../../../shared/contexts/ScrapeDateContext'
import getUniqueCategories from '../../visualizations/single_visualization/utils/getUniqueCategories'
import ToggleButtons from '../../components/ToggleButtons.js'
import { graphemeAt } from 'voca'
import OpenFields from '../../components/OpenFields'

// Function based component that displays the search bar and table with all the datapoints that can be selected
function DataSelect() {

  const { tableUtils, setTableUtils } = useTableUtils()

  // We need to keep a reference of the columns
  // Used in ranking the data when applying a search
  const { columnSelection } = useColumnSelection()
  let columnsRef = useRef(columnSelection)
  let queryRef = useRef(tableUtils.search)

  // We update the columns if a new search is initialized
  if (tableUtils.search !== queryRef.current) {
    columnsRef.current = columnSelection
    queryRef.current = tableUtils.search
  }

  const scrapeDate = useScrapeDate()

  // Update the data based on the search, filters and sorters
  // The search, filters and sorters are all located in the tableUtils context
  // Also rank the data based on the current selected columns
  const allData = useData()
  const updatedData = updateData(allData, tableUtils, columnsRef.current)

  // Handler that is called after the filters and sorters in the menu are applied
  const menuUpdate = (filters, sorters) => {
    // Update the filters and sorters context with the ones applied in the menu
    setTableUtils({
      ...tableUtils,
      filters: filters,
      sorters: sorters,
    })
  }

  // Store the categories of each variable (which are used in the filter options)
  const [categories, setCategories] = useState(null)
  useEffect(() => {
    setCategories(getUniqueCategories(allData))
  }, [allData])

  // Helper function to check if the filter is the default empty placeholder
  const isDefaultFilter = (filter) => {
    return (
      filter.selected === "" &&
      filter.input.length === 1 &&
      filter.input[0].var === ""
    )
  }

  // Get the number of rows in total and after filters are applied
  const totalRows = allData.length
  const filteredRows = updatedData.length
  const filtersApplied = (tableUtils.filters &&
    tableUtils.filters.some(filter => !isDefaultFilter(filter))) || totalRows != filteredRows

  return (
    <>
      <Search
        tour="step-data-search"
        update={(e) => setTableUtils({ ...tableUtils, search: e })}
        initial={tableUtils.search}
      />
      <div tour="step-data-select" className="med-content-container">

        <h1 className="med-header">
          Medicines
          <span> - Database last updated on {scrapeDate}</span>
          <span>
            {filtersApplied
              ? ` - ${filteredRows} out of ${totalRows} medicines displayed`
              : ` - ${totalRows} medicines available`}
          </span>
        </h1>

        <Menu
          filters={tableUtils.filters}
          sorters={tableUtils.sorters}
          update={menuUpdate}
          categories={categories}
        />

        <hr className="med-top-separator" />
        <TableView
          data={updatedData}
          sorters={tableUtils.sorters}
          setSorters={(e) => setTableUtils({ ...tableUtils, sorters: e })}
          select={true}
          text="No data to display, please clear your search or filters."
          human={true}
        />
        {/* <hr className="med-top-separator" />
        <h3>
          *: The source document was uploaded before the introduction of this variable
        </h3> */}
      </div>
    </>
  )
}

export default DataSelect
