// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import './InfoPage.css'
import '../../shared/shared.css'
import Search from '../../shared/search/Search'
import { useNavigate } from 'react-router-dom'
import { useTableUtils } from '../../shared/contexts/TableUtilsContext'

// Function based component rendering the info page
function InfoPage() {
  const navigate = useNavigate()
  const { tableUtils, setTableUtils } = useTableUtils()

  // Set the query in the utils context and navigate to the datapage
  const search = (query) => {
    setTableUtils({ ...tableUtils, search: query })
    navigate('/medicines')
  }

  return (
    // Infopage components, contains article containers
    <div className="med-info-content">
      <Search update={search}/>
      <section className="med-content-container">
        <h1>About the European Medicines Regulatory Database</h1>
        <hr className="med-top-separator" />
        <p>
          The European Medicines Regulatory Database (EMRD) is a free-to-access interactive dashboard that stores and
          displays monthly-updated information on the regulation of medicines for human use in the European Union (EU).
          EMRD aggregates information from various regulatory sources and documents such as legal decisions, public
          assessment reports and drug labels to create a comprehensive database of how medicinal products for human use
          in the EU are regulated over the medicines life-cycle including key events such as orphan designations,
          marketing authorisation procedures and post-marketing drug regulatory actions. The intended use of EMRD is to
          support researchers studying drug development, regulation and use in fields such as drug regulatory science,
          drug innovation, clinical pharmacology and pharmaco-epidemiology. EMRD also intends to serve other
          stakeholders such as drug regulators, developers and patients as well as students who would like to learn more
           about the European drug regulatory system and regulation of individual medicines.
        </p>
        <p>
          We ask that users who make use of EMRD data as part of their research cite the EMRD manuscript in any
          resulting publications.
        </p>
      </section>

      <section className="med-content-container">
        <h1>Citing</h1>
          <p>
            -- insert reference here --
          </p>
      </section>

      <section className="med-content-container">
        <h1>License</h1>
          <p>
            All data and metadata downloadable from this dashboard are licensed under a {' '}
            <a
              href="https://creativecommons.org/licenses/by/4.0/"
              target="_blank"
              rel="noreferrer"
              className="med-link"
            >
              Creative Commons Attribution 4.0 International
            </a>
            . Data is maintained and managed using FAIR principles.
          </p>
      </section>

      <section className="med-content-container">
        <h1>Data</h1>
        <hr className="med-top-separator" />

        <article>
          <h2>Data model</h2>
          <p>
            The European Medicines Regulatory Database is structured according to a data model that currently consists
            of five data domains:
          </p>
          <ol style={{ listStyle: 'none' }}>
                    <li>1) Medicinal product</li>
                    <li>2) Ingredients & substances</li>
                    <li>3) Marketing Authorisation</li>
                    <li>4) Procedures</li>
                    <li>5) Orphan products</li>
                </ol>
          <p>
            Part of the data model is based on the FHYR standard. A graphical
            presentation of the data model can be downloaded {' '}
            <a
              href="https://www.ema.europa.eu"
              target="_blank"
              rel="noreferrer"
              className="med-link"
            >
              here
            </a>
            .
          </p>
        </article>

        <article>
          <h2>Data dictionary</h2>
          <p>
            The dictionary provides detailed data descriptions and source information for each data element in the
            European Medicines Regulatory Database. The dictionary can be downloaded {' '}
            <a
              href="https://ec.europa.eu/health/documents/community-register_en"
              target="_blank"
              rel="noreferrer"
              className="med-link"
            >
              here
            </a>
            .
          </p>
        </article>
        <article>
          <h2>Data provenance</h2>
          <p>
            MRD is primarily created based on regulatory documents and information extracted from the European
            Commission Union Register of Medicinal Products and the European Medicines Agency website. Source
            information for the different variables is provided in the data dictionary.
          </p>
        </article>
        <article>
          <h2>Data updates</h2>
          <p>
            The data in the dashboard is updated monthly. To see when the latest update took place, visit the {' '}
             <a
              href="https://emrd.uu.nl/medicines"
              target="_blank"
              rel="noreferrer"
              className="med-link"
            >
              data-tab
            </a>
            .
          </p>
        </article>
      </section>
      <section className="med-content-container">
        <h1>Release notes</h1>
          <p>
            Version 1.0 of the European Medicines Regulatory Database was released on 01 april 2024.
          </p>
      </section>
      <section className="med-content-container">
        <h1>Disclaimer</h1>
          <p>
            The contents of EMRD are intended for educational and scientific research purposes only. Every effort has
            been made to ensure the accuracy and completeness of the information that is provided on this website.
            However, Utrecht University and the Dutch Medicines Evaluation Board make no warranties regarding errors
            in the data, and assume no responsibility for the use of the EMRD.
          </p>
      </section>
      <section className="med-content-container">
        <h1>Contact or report issue</h1>
          <p>
            To contact us, please email at [email address].
          </p>
          <p>
            Report issues and bugs at our {' '}
            <a
              href="https://github.com/cbg-meb/medicines-regulatory-db/issues"
              target="_blank"
              rel="noreferrer"
              className="med-link"
            >
              Github page
            </a>
            .
          </p>
      </section>
    </div>
  )
}

export default InfoPage
