// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react';

// Function based component, represents a single detail entry, which
// preferably resides inside a detail group component
function Detail(props) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [showExplanation, setShowExplanation] = React.useState(false);
  const [isExtended, setIsExtended] = React.useState(false); // State to track if scroll box is fully extended
  const explanationRef = React.useRef(null);
  let hoverTimeout;
  let leaveTimeout;

  const descriptions = {
    "eu_pnumber": "Identifier issued by the European Commission for treatments ever authorised in the European Union",
    "ema_number": "Identifier issued by the EMA after submission of the initial application for marketing authorisation",
    "eu_atmp": "Advanced therapy medicinal products (ATMPs) are medicines for human use that are based on genes, tissues or cells, as defined in Regulation (EC) No 1394/2007",
    "eu_indication_initial": "A medical condition that a medicinal products was used for at the time of marketing authorisation. This can include the treatment, prevention and diagnosis of a disease. This therapeutic indication is stated in section 4.1 of the SmpC",
    "eu_indication_current": "A medical condition that a medicinal products is used for as per latest update of the database. This can include the treatment, prevention and diagnosis of a disease. This therapeutic indication is stated in section 4.1 of the SmpC",
    "eu_referral": "A referral is a post-authorisation procedure used to resolve issues such as concerns over the safety or benefit-risk balance of a medicine or a class of medicines. In a referral, the EMA is requested to conduct a scientific assessment on behalf of the EU",
    "eu_suspension": "The suspension of a marketing authorisation is a precautionary measure, during which time a medicinal product is not available",
    "eu_brand_name_initial": "The brand name of a medicine at the time of marketing authorisation",
    "eu_brand_name_current": "The current brand name as per last update of the database",
    "eu_prime_initial": "PRIME (PRIority Medicines) is a scheme run by the EMA to enhance support for the development of medicines that target an unmet medical need",
    "eu_aut_status": "The authorisation status of the medicinal product, categorised as either Active or Withdrawn. In case of a status “withdrawn” the medicinal product has previously been authorised",
    "eu_aut_type_current": "The current type of EU authorisation as per latest update of the database, which can be Conditional, Exceptional or Standard",
    "eu_aut_type_initial": "The type of EU authorisation at the time of marketing authorisation, which can be Conditional, Exceptional or Standard",
    "eu_mah_current": "The current marketing authorisation holder (MAH) of the medicinal product as per latest update of the database",
    "eu_mah_initial": "The initial marketing authorisation holder at the time of initial authorisation of the medicinal product",
    "eu_legal_basis": "The legal basis of the authorisation procedure, see the metadata file for a detailed description of legal bases",
    "eu_od_at_marketing_bool": "A status assigned by the EMA at the time of marketing authorisation to a medicine intended for use against a rare condition",
    "eu_od_con_first": "The oldest status assigned by the EMA to a medicine intended for use against a rare condition",
    "active_substance": "The substance responsible for the activity of a medicine",
    "atc_code": "The Anatomical Therapeutic Chemical (ATC) classification system: the active substances are classified in a hierarchy with five different levels according to the organ or system on which they act and their therapeutic, pharmacological and chemical properties",
    "atc_code_nameL1": "The first letter of the ATC code describes one of the 14 main groups of the ATC classification system",
    "atc_code_nameL2": "The digits following the first letter indicate the therapeutic group",
    "atc_code_nameL3": "The letter following the digits indicates a pharmacological subgroup",
    "atc_code_nameL4": "The consecutive letter indicates the chemical subgroup",
    "atc_code_nameL5": "The ending digits and thus the lowest level of the ATC classification system corresponds to the active substance",
    "eu_nas": "An active substance that is not previously authorised in a medicinal product for human use in the European Union and that is from a chemical structure point of view not related to any other authorised substances",
    "ema_procedure_start_initial": "TThe start date of the EMA marketing authorisation procedure",
    "chmp_opinion_date": "The initial opinion date from the Committee for Medicinal Products for Human Use (CHMP) of the EMA",
    "eu_aut_date": "The date of the initial authorisation by the European Commission",
    "smpc_url": "URL to the EU authorisation annex document of the initial marketing authorisation procedure hosted on the EC Union Register of medicinal products for human use",
    "epar_url": "URL to the EMA's initial authorisation EPAR document hosted on the EMA website",
    "ema_url": "URL to the EMA's medicinal product page",
    "ec_url": "URL to the medicinal product page of the EC Union Register of medicinal products for human use",
    "aut_url": "URL to the EU authorisation decision document of the initial marketing authorisation procedure hosted on the EC Union Register of medicinal products for human use",
    "ema_rapp": "The Rapporteur is one of the two members of the CHMP that lead the evaluation of an application of a medicinal product for initial authorisation",
    "ema_corapp": "The Co-Rapporteur is one of the two members of the CHMP that lead the evaluation of an application of a medicinal product for initial authorisation",
    "rapp_country": "The country which the Rapporteur was representing at the time of the evaluation of an application of a medicinal product for initial authorisation",
    "ema_reexamination": "An applicant can request a re-examination of the CHMP’s opinion on the marketing authorisation",
    "eu_accel_assess_g": "Accelerated assessment reduces the timeframe for the CHMP to review a marketing authorisation application from 210 days to 150 days. Applications may be eligible  if the CHMP decides the product is of major interest for public health and therapeutic innovation",
    "assess_time_days_total": "Total duration of the assessment procedure, i.e. active days plus clock-stop days",
    "assess_time_days_cstop": "The clock stop happens after day 120 and may also happen after day 180 during the initial assessment procedure, when the CHMP has adopted a list of questions or outstanding issues to be addressed by the applicant",
    "assess_time_days_active": " The time spent by the CHMP to evaluate the evidence provided by the applicant in support of a marketing authorisation application",
    "ec_decision_time_days": "Duration between initial EU authorisation date and initial CHMP opinion date in days"
  }

  const getAttributeDescription = () => {
    if (descriptions[props.attribute_name]) {
      return descriptions[props.attribute_name]
    }
    // default text if no description found
    console.log(props.attribute_name)
    return 'No description available';
  };

  const handleMouseEnter = () => {
    if (!explanationRef.current) {
      hoverTimeout = setTimeout(() => {
        setIsHovered(true);
        explanationRef.current = true;
      }, 1000);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    clearTimeout(leaveTimeout);

    leaveTimeout = setTimeout(() => {
      setIsHovered(false);

      // Keep the explanation box open if it was manually toggled
      if (!showExplanation) {
        explanationRef.current = false;
      }
    }, 300);
  };

  const handleToggleExplanation = () => {
    setShowExplanation(!showExplanation);
  };

  const handleExplanationClick = (e) => {
    // Stop the event propagation to prevent hiding the box when clicking inside it
    e.stopPropagation();

    // Toggle the explanation box visibility
    setShowExplanation(!showExplanation);

    // If the explanation box is manually toggled, consider it hovered
    explanationRef.current = !showExplanation;
  };

  const handleValueClick = () => {
    setIsExtended(!isExtended); // Toggle the state to extend or reduce the scroll box
  };

  // Cleanup timeouts on unmount
  React.useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout);
      clearTimeout(leaveTimeout);
    };
  }, []);

  return (
    <div className="med-info-detail">
      <span
        className="med-info-detail-name"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleToggleExplanation}
      >
        {props.name}{' '}
        <span
          className={`question-mark ${isHovered || showExplanation ? 'show-explanation' : ''}`}
          onClick={handleExplanationClick}
        >
          ?
        </span>
        {(isHovered || showExplanation) && (
          <div
            className={`explanation-box`}
            onClick={(e) => e.stopPropagation()} // Prevent hiding the box when clicking inside it
          >
            <div className="explanation-text">
              {getAttributeDescription()}
            </div>
          </div>
        )}
      </span>
      <span
        className={`med-info-detail-value ${isExtended ? 'extended' : ''}`} // Apply extended class if isExtended is true
        onClick={handleValueClick} // Toggle isExtended state when clicked
      >
        {props.value}
      </span>
    </div>
  );
}

export default Detail;